@import '~antd/dist/antd.css';
@import '~react-perfect-scrollbar/dist/css/styles.css';

.ReactModal__Overlay {
  opacity: 0;
  transition: all 200ms ease-in-out;
  z-index: 11;
}

.ReactModal__Body--open {
  overflow: hidden;
}

.ReactModal__Overlay--after-open {
  opacity: 1;
}

.ReactModal__Overlay--before-close {
  opacity: 0;
}

.anticon {
  vertical-align: 0 !important;
}
